import type { ZodIssue } from "zod";
import { ZodIssueCode } from "zod";

export const CustomErrorKeys = {
	InvalidPhoneNumber: "INVALID_PHONE_NUMBER",
	WeakPassword: "WEAK_PASSWORD",
	PasswordsCantMatch: "PASSWORDS_CANT_MATCH",
	CompanyNameMissing: "COMPANY_NAME_MISSING",
	InvalidPostalCodeHouseNumber: "INVALID_POSTAL_CODE_HOUSE_NUMBER",
	InvalidVatNumber: "INVALID_VAT_NUMBER",
	InvalidVatNumberFormat: "INVALID_VAT_NUMBER_FORMAT",
	VatServerError: "VAT_SERVER_ERROR",
	InvalidDateOfBirth: "INVALID_DATE_OF_BIRTH",
	CustomerAlreadyExists: "CUSTOMER_ALREADY_EXISTS",
	InvalidCredentials: "INVALID_CREDENTIALS",
	InvalidCurrentPassword: "INVALID_CURRENT_PASSWORD",
	InvalidAccountType: "INVALID_ACCOUNT_TYPE",
} as const;

/**
 * @param messageKey The key used to output a custom error message
 * @param path The property path of the json input. E.g. `["address", "postalCode"]`.
 * If not given, this will be considered a global input issue.
 */
export const customZodIssue = (
	messageKey: keyof typeof CustomErrorKeys,
	path?: string[],
): ZodIssue => ({
	code: ZodIssueCode.custom,
	path: path ?? [],
	params: { code: CustomErrorKeys[messageKey] },
	message: "",
});

export const requiredZodIssue = (path: string[]): ZodIssue => ({
	code: ZodIssueCode.too_small,
	minimum: 1,
	type: "string",
	inclusive: true,
	path,
	message: "",
});

export type CustomIssue = {
	code: "custom";
	path?: string[];
	params: { code: (typeof CustomErrorKeys)[keyof typeof CustomErrorKeys] };
};
