"use client";

import { Root as LabelPrimitive } from "@radix-ui/react-label";
import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import { cn } from "../../helpers/styles";

/**
 * Label usable for form fields. Based on `@radix-ui/react-label`.
 */
export const Label = forwardRef<
	ElementRef<typeof LabelPrimitive>,
	ComponentPropsWithoutRef<typeof LabelPrimitive>
>(({ className, ...props }, ref) => {
	return (
		<LabelPrimitive
			ref={ref}
			className={cn("font-semibold text-black text-sm md:text-base", className)}
			{...props}
		/>
	);
});

Label.displayName = LabelPrimitive.displayName;
