import { zodResolver } from "@hookform/resolvers/zod";
import { objectMap } from "@labdigital/toolkit";
import type { DefaultValues } from "react-hook-form";
import { useForm as useHookForm } from "react-hook-form";
import type { z } from "zod";

export const useForm = <
	T extends // biome-ignore lint: lint/suspicious/noExplicitAny
		| z.ZodEffects<any>
		// biome-ignore lint: lint/suspicious/noExplicitAny
		| z.ZodObject<any>
		// biome-ignore lint: lint/suspicious/noExplicitAny
		| z.ZodIntersection<any, any>
		// biome-ignore lint: lint/suspicious/noExplicitAny
		| z.ZodDiscriminatedUnion<any, any[]>
		// biome-ignore lint: lint/suspicious/noExplicitAny
		| z.ZodUnion<any>
		// biome-ignore lint: lint/suspicious/noExplicitAny
		| z.ZodRecord<any, any>,
>(
	schema: T,
	defaultValues?: Partial<z.infer<T>>,
) =>
	useHookForm({
		resolver: zodResolver(schema),
		defaultValues: { ...getDefaultValues(schema), ...defaultValues },
	});

/**
 * Traverses a zod schema and returns all default values for all fields.
 *
 * For DiscriminatedUnions, the first option is assumed to be the default.
 *
 * @example
 * ```ts
 * getDefaultValues(
 * 	z.object({
 * 		toggle: z.boolean().default(true),
 * 		value: z.string().default("")
 * 	}),
 * ) // { toggle: true, value: "" }
 *
 * getDefaultValues(
 * 	z.discriminatedUnion("toggle", [
 * 		z.object({ toggle: z.literal(false) }),
 * 		z.object({ toggle: z.literal(true), value: z.string() }),
 * 	])
 * ) // { toggle: false }
 *
 * ```
 */
export const getDefaultValues = <T extends z.ZodTypeAny>(
	schema: T,
): DefaultValues<z.infer<T>> => {
	if (isIntersection(schema)) {
		return {
			...getDefaultValues(schema._def.left),
			...getDefaultValues(schema._def.right),
		};
	}
	if (isDiscriminatedUnion(schema)) {
		// always assume the first option is the default
		return getDefaultValues(schema.options[0]);
	}
	if (isZodEffects(schema)) {
		return getDefaultValues(schema._def.schema);
	}
	if (isZodObject(schema)) {
		return getObjectDefaultValues(schema) as DefaultValues<z.infer<T>>;
	}
	if (isZodUnion(schema)) {
		return getDefaultValues(schema._def.options[0]);
	}

	throw new Error("Unsupported schema type");
};

// biome-ignore lint: lint/suspicious/noExplicitAny
const getObjectDefaultValues = <T extends z.ZodObject<any>>(schema: T) =>
	objectMap(schema._getCached().shape, {
		getValue: getDefaultValue,
	});

/**
 * A single field value
 */
// biome-ignore lint: lint/suspicious/noExplicitAny
const getDefaultValue = (schema: any): any => {
	if (!schema || !schema._def) {
		return undefined;
	}
	if (isZodLiteral(schema)) {
		return schema._def.value;
	}
	if (isZodNativeEnum(schema)) {
		return schema._def.values[0];
	}
	if (isZodOptional(schema)) {
		return getDefaultValue(schema._def.innerType);
	}
	if (isDiscriminatedUnion(schema)) {
		return getDefaultValues(schema.options[0]);
	}
	if (isZodUnion(schema)) {
		return getDefaultValue(schema._def.options[0]);
	}

	return schema._def.defaultValue?.();
};

// biome-ignore lint: lint/suspicious/noExplicitAny
const isZodEffects = <I extends z.ZodEffects<any>>(schema: any): schema is I =>
	schema._def?.typeName === "ZodEffects";

// biome-ignore lint: lint/suspicious/noExplicitAny
const isZodObject = <I extends z.ZodObject<any>>(schema: any): schema is I =>
	schema._def?.typeName === "ZodObject";

// biome-ignore lint: lint/suspicious/noExplicitAny
const isZodLiteral = <I extends z.ZodLiteral<any>>(schema: any): schema is I =>
	schema._def?.typeName === "ZodLiteral";

// biome-ignore lint: lint/suspicious/noExplicitAny
const isZodNativeEnum = <I extends z.ZodNativeEnum<any>>(
	// biome-ignore lint: lint/suspicious/noExplicitAny
	schema: any,
): schema is I => schema._def?.typeName === "ZodNativeEnum";

// biome-ignore lint: lint/suspicious/noExplicitAny
const isZodOptional = <I extends z.ZodOptional<any>>(
	// biome-ignore lint: lint/suspicious/noExplicitAny
	schema: any,
): schema is I => schema._def?.typeName === "ZodOptional";

// biome-ignore lint: lint/suspicious/noExplicitAny
const isIntersection = <I extends z.ZodIntersection<any, any>>(
	// biome-ignore lint: lint/suspicious/noExplicitAny
	schema: any,
): schema is I => schema._def?.typeName === "ZodIntersection";

const isDiscriminatedUnion = (
	// biome-ignore lint: lint/suspicious/noExplicitAny
	schema: any,
	// biome-ignore lint: lint/suspicious/noExplicitAny
): schema is z.ZodDiscriminatedUnion<any, any[]> =>
	schema._def?.typeName === "ZodDiscriminatedUnion";

// biome-ignore lint: lint/suspicious/noExplicitAny
const isZodUnion = (schema: any) => schema._def?.typeName === "ZodUnion";
