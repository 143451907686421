import type { HTMLAttributes, ReactNode } from "react";
import type {
	FieldValues,
	Path,
	UseFormHandleSubmit,
	UseFormReturn,
} from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { ServerError } from "~/lib/helpers/errors";
import { issueToError, useFormErrorTranslations } from "./useFormTranslations";

type Props<TValues extends FieldValues> = {
	form: UseFormReturn<TValues>;
	onSubmit: (values: TValues) => Promise<void> | void;
	children: ReactNode;
} & Omit<HTMLAttributes<HTMLElement>, "onSubmit">;

export function Form<TValues extends FieldValues>({
	form,
	onSubmit,
	children,
	...attributes
}: Props<TValues>) {
	useFormErrorTranslations();

	return (
		<FormProvider {...form}>
			<form
				noValidate
				onSubmit={handleServerErrors(form, form.handleSubmit(onSubmit))}
				{...attributes}
			>
				{children}
			</form>
		</FormProvider>
	);
}

/**
 * Wrapper function that catches any errors coming from the submitHandler and adds them to the error object on the form
 * This can then be used to display an error message to the user.
 */
const handleServerErrors =
	<
		TFieldValues extends FieldValues,
		TTransformedValues extends FieldValues = TFieldValues,
	>(
		form: UseFormReturn<TFieldValues>,
		submitHandler: ReturnType<
			UseFormHandleSubmit<TFieldValues, TTransformedValues>
		>,
	) =>
	async (e?: React.BaseSyntheticEvent) => {
		try {
			return await submitHandler(e);
		} catch (error) {
			// Server error can be a collection of GraphQL errors, for now we opt to just use the first one
			if (error instanceof ServerError) {
				handleGraphQLErrors(error, form);
				// form.setError("root", { message: `Error: ${error.errors[0].message}` });
			} else {
				form.setError("root", issueToError({ code: "custom", path: [] }));
			}
		}
	};

const handleGraphQLErrors = <T extends FieldValues>(
	error: ServerError,
	form: UseFormReturn<T>,
	ignoreErrorCodes: string[] = [],
) => {
	let firstErrorPath: Path<T> | undefined;

	for (const graphqlError of error?.errors ?? []) {
		// Set form field errors if the GraphQL error has form field errors
		if (
			graphqlError.extensions.code === "BAD_USER_INPUT" &&
			graphqlError.extensions.errors?.issues
		) {
			for (const issue of graphqlError.extensions.errors.issues) {
				const path = issue.path.join(".") as Path<T>;

				form.setError(path, issueToError(issue));
				if (!firstErrorPath) {
					firstErrorPath = path;
				}
			}
		} else {
			if (
				graphqlError.extensions.errorCode &&
				ignoreErrorCodes.includes(graphqlError.extensions.errorCode)
			) {
				continue;
			}
			// Set global form error otherwise
			form.setError(
				"root",
				issueToError({
					code: "custom",
					path: [],
					message: graphqlError.extensions.code,
				}),
			);
		}
	}
	// scroll and focus to first input field with error
	if (firstErrorPath) form.setFocus(firstErrorPath);
};
