import { useTranslations } from "next-intl";

export type ServerErrorMessage =
	| { message: "server-error" }
	| { message: "invalid" }
	| { message: "required" }
	| { message: "number-too-big"; value: string }
	| { message: "number-too-small"; value: string }
	| { message: "string-too-long"; value?: string }
	| { message: "string-too-short"; value?: string }
	| { message: "invalid-credentials" }
	| { message: "invalid-phone-number" }
	| { message: "invalid-current-password" }
	| { message: "password-too-weak" }
	| { message: "passwords-cant-match" }
	| { message: "company-name-missing" }
	| { message: "invalid-postalcode-housenumber" };

/**
 * Component that returns the correct server error based on a string identifier
 *
 * This way we don't need to abstract away useTranslations into a bunch of helper functions
 */
export const ServerError = ({
	error,
	value,
}: {
	error: ServerErrorMessage;
	value?: string;
}) => {
	const t = useTranslations("FormServerError");

	switch (error.message) {
		case "server-error":
			return t("server-error");
		case "required":
			return t("required");
		case "number-too-big":
			return t("number-too-big", { value });
		case "number-too-small":
			return t("number-too-small", { value });
		case "string-too-long":
			return t("string-too-long", { value });
		case "string-too-short":
			return t("string-too-short", { value });
		case "invalid-credentials":
			return t("invalid-credentials");
		case "invalid-phone-number":
			return t("invalid-phone-number");
		case "invalid-current-password":
			return t("invalid-current-password");
		case "password-too-weak":
			return t("password-too-weak");
		case "passwords-cant-match":
			return t("passwords-cant-match");
		case "company-name-missing":
			return t("company-name-missing");
		case "invalid-postalcode-housenumber":
			return t("invalid-postalcode-housenumber");
		default:
			return t("invalid");
	}
};
