/**
 * Interpolate a string with values
 * @example interpolate("Hello {name}", { name: "John" }) // "Hello John"
 * @param template The template to interpolate
 * @param values An object with values to replace
 * @returns The interpolated label
 */
export const interpolate = (
	template: string,
	values: Record<string, string | number>,
): string => {
	let message = template;
	if (Object.keys(values).length > 0) {
		for (const [key, value] of Object.entries(values)) {
			message = message.replace(`{${key}}`, value.toString());
		}
	}

	return message;
};
