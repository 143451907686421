"use client";

import { CustomErrorKeys } from "@commerce-shared/validation";
import { useMessages } from "next-intl";
import { useEffect } from "react";
import { z } from "zod";
import { interpolate } from "~/lib/i18n/helpers";
import type { ServerErrorMessage } from "./ServerError";

export const useFormErrorTranslations = (): void => {
	const labels = useMessages().FormServerError as Record<
		ServerErrorMessage["message"],
		string
	>;

	useEffect(() => {
		setFormErrorsTranslations(labels);
	}, [labels]);
};

export const setFormErrorsTranslations = (
	labels: Record<ServerErrorMessage["message"], string>,
): void =>
	z.setErrorMap((issue) => {
		switch (issue.code) {
			case "invalid_string":
				return {
					message: labels.invalid,
				};
			case "invalid_date":
				return {
					message: labels.invalid,
				};
			case "invalid_enum_value":
				return {
					message: labels.invalid,
				};
			case "too_big":
				switch (issue.type) {
					case "number":
					case "bigint":
						return {
							message: interpolate(labels["number-too-big"], {
								value: issue.maximum.toString(),
							}),
						};
					default:
						return {
							message: interpolate(labels["string-too-long"], {
								value: issue.maximum.toString(),
							}),
						};
				}
			case "too_small":
				switch (issue.type) {
					case "number":
					case "bigint":
						return {
							message: interpolate(labels["number-too-small"], {
								value: issue.minimum.toString(),
							}),
						};
					default:
						return {
							message:
								issue.minimum === 1
									? labels.required
									: interpolate(labels["string-too-short"], {
											value: issue.minimum.toString(),
										}),
						};
				}
		}

		return {
			message: labels.invalid,
		};
	});

export const issueToError = (
	issue: z.ZodIssueOptionalMessage,
): ServerErrorMessage => {
	switch (issue.code) {
		// Basic error codes from Zod
		case "invalid_string":
			if (issue.validation === "datetime") {
				return {
					message: "required",
				};
			}
			return {
				message: "invalid",
			};
		case "invalid_date":
			return {
				message: "invalid",
			};
		case "invalid_enum_value":
			return {
				message: "invalid",
			};
		case "too_big":
			switch (issue.type) {
				case "number":
				case "bigint":
					return {
						message: "number-too-big",
						value: issue.maximum.toString(),
					};
				default:
					return {
						message: "string-too-long",
						value: issue.maximum.toString(),
					};
			}
		case "too_small":
			switch (issue.type) {
				case "number":
				case "bigint":
					return {
						message: "number-too-small",
						value: issue.minimum.toString(),
					};
				default:
					return issue.minimum === 1
						? { message: "required" }
						: { message: "string-too-short", value: issue.minimum.toString() };
			}
		// Non-default Zod errors
		case "unrecognized_keys":
			switch (issue.keys[0]) {
				case CustomErrorKeys.InvalidCredentials:
					return { message: "invalid-credentials" };
				case CustomErrorKeys.InvalidPhoneNumber:
					return { message: "invalid-phone-number" };
				case CustomErrorKeys.InvalidCurrentPassword:
					return { message: "invalid-current-password" };
				case CustomErrorKeys.WeakPassword:
					return { message: "password-too-weak" };
				case CustomErrorKeys.PasswordsCantMatch:
					return { message: "passwords-cant-match" };
				case CustomErrorKeys.CompanyNameMissing:
					return { message: "company-name-missing" };
				case CustomErrorKeys.InvalidPostalCodeHouseNumber:
					return {
						message: "invalid-postalcode-housenumber",
					};
				default:
					return { message: "invalid" };
			}
		// Server errors
		case "custom":
			switch (issue.message) {
				case CustomErrorKeys.InvalidCredentials:
					return { message: "invalid-credentials" };
				case CustomErrorKeys.InvalidCurrentPassword:
					return { message: "invalid-current-password" };
				default:
					return { message: "server-error" };
			}
	}

	return {
		message: "invalid",
	};
};
