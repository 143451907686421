import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import { cn } from "../../helpers/styles";

/**
 * Container for individual form fields which contains the label, input and potential description/error messages
 */
export const FormField = forwardRef<
	ElementRef<"div">,
	ComponentPropsWithoutRef<"div">
>(({ className, children, ...props }, ref) => {
	return (
		<div ref={ref} className={cn("grid gap-2", className)} {...props}>
			{children}
		</div>
	);
});

FormField.displayName = "FormField";

/**
 * FormMessage component that is used to display form errors.
 */
export const FormMessage = forwardRef<
	ElementRef<"p">,
	ComponentPropsWithoutRef<"p">
>(({ className, children, ...props }, ref) => {
	return (
		<p
			ref={ref}
			className={cn("font-medium text-red-600 text-sm", className)}
			{...props}
			role="alert"
			aria-live="polite"
		>
			{children}
		</p>
	);
});

FormMessage.displayName = "FormMessage";
